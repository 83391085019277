import { PermissionObjectType, Tuple } from "../types";

export function getTeamPermission(
  permissions: Tuple[],
  type: PermissionObjectType,
  teamId: number,
): Tuple["relation"] | undefined {
  const idRegex = new RegExp(`^team:${teamId}#member$`); // Regular expression to match "team:123#member"

  return permissions.find(
    (tuple: Tuple) => tuple.object.includes(type) && idRegex.test(tuple.user),
  )?.relation;
}
