import { Permission, PermissionObjectType, Tuple } from "../types";

export function getOrgPermission(
  permissions: Tuple[],
  type: PermissionObjectType,
  accountId: number | undefined,
) {
  if (accountId) {
    return permissions.find(
      (permission) =>
        permission.object.includes(type) &&
        permission.user === `account:${accountId}#member`,
    )?.relation as Permission | undefined;
  }
}
