import styles from "./Upsell.module.css";
import { Button, Text, Icon } from "@zapier/design-system";

type Props = {
  onUpsellButtonClick?: () => void;
};

export function Upsell({ onUpsellButtonClick }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.col1}>
        <Icon name="ratingStarFill" color="sun80" size={26} />
      </div>
      <div className={styles.col2}>
        <Text type="paragraph3Semibold" color="earth">
          Inviting members is a Team Plan feature
        </Text>
        <Text type="smallPrint1" color="earth">
          Unlock the power of multi-user and collaboration by upgrading or
          starting a free trial.
        </Text>
        <div className={styles.btnWrapper}>
          <Button
            color="primary"
            size="xsmall"
            href="/app/planbuilder/plans"
            target="_blank"
            onClick={onUpsellButtonClick}
          >
            View plans
          </Button>
        </div>
      </div>
      <div></div>
    </div>
  );
}
