import styles from "./MenuItem.module.css";
import { Link } from "@zapier/design-system";
import { useEffect, useRef } from "react";

type GetItemPropsReturn = {
  id: string;
  role: "option";
  "aria-selected": boolean;
};

export type Props = {
  ariaLabel: string;
  /**
   * What to render in the `MenuItem`.
   */
  // What to render within `MenuItem`.
  children: React.ReactNode;
  /** A Component to pass into the `MenuItem` if you'd like to render a different kind of link,
   * like a Router Link, for example
   * Only if the `Tag` is a link
   */
  component?: React.ElementType;
  /**
   * Indicates whether the item should be disabled
   */
  disabled?: boolean;
  /**
   * Optional property which, when supplied, will render `MenuItem` as an `a` tag.
   */
  href?: string;
  /**
   * Whether the item is currently selected. Should be used in circumstances
   * where items are ephemerally chosen.
   */
  isSelected?: boolean;
  /**
   * Optional `id` so the node can be referenced.
   */
  id?: string;
  /**
   * Optional `rel` to be applied to the menu item if it is rendered as an anchor tag and not a button.
   */
  rel?: string;
  /**
   * The optional a11y role for the item. `menuitem` is the semantic equivalent of `<li>`
   *  and should be used when the parent has a role of 'menu',
   *  while 'option' is the semantic equivalent of `<option>`and
   *  should be used when the parent has the role of 'listbox'.
   */
  role?: "option" | "menuitem";
  /**
   * Optional click handler for the `MenuItem`.
   */
  onClick?: (event: React.MouseEvent) => void;
  /** Adds divider on top */
  hasTopDivider?: boolean;
  /** Need this when used with Dropdown */
  itemProps?: GetItemPropsReturn;
  /** Highlight the item on arrow up or down */
  isHighlighted?: boolean;
  /** Focus on arrow down - should be false when used inside a Dropdown since Downshift handles this already */
  shouldHandleFocus?: boolean;
};

/* This is a fork of the Zinnia MenuItem 'cuz we need the height of each item to not be fixed at 44px */
const MenuItem = (props: Props) => {
  const shouldHandleFocus = props.shouldHandleFocus ?? false;

  const Tag = props.href && !props.disabled ? Link : "button";
  const isLink = Tag === Link;
  const ariaLabel = props.isSelected
    ? `${props.ariaLabel}, selected`
    : props.ariaLabel;
  const conditionalProps = isLink
    ? {
        ariaLabel,
        href: props.href,
        rel: props.rel,
      }
    : {
        "aria-label": ariaLabel,
        type: "button" as const,
        disabled: props.disabled,
      };
  const liRole = props.role ? { role: "none" } : null;
  const menuItemClasses = `${styles.menuItem} ${
    props.isHighlighted ? styles.menuItemHighlight : ""
  } ${props.isSelected ? styles.menuItemSelected : ""}`;

  const itemRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!shouldHandleFocus) {
      return;
    }

    if (props.isHighlighted && itemRef.current) {
      itemRef.current.focus();
    }
  }, [shouldHandleFocus, props.isHighlighted]);

  return (
    <li
      {...props.itemProps}
      {...liRole}
      className={`${styles.li} ${props.hasTopDivider ? styles.divider : ""}`}
    >
      <Tag
        {...conditionalProps}
        ref={itemRef as any}
        component={isLink ? props.component : undefined}
        // @ts-ignore doesn't understand Link[color=null]
        color={isLink ? null : undefined}
        className={menuItemClasses}
        href={props.href}
        onClick={props.onClick}
        role={props.role}
      >
        <span>{props.children}</span>
      </Tag>
    </li>
  );
};

export default MenuItem;
