import { AssetType, Tuple } from "../types";
import { getMemberIdsByType } from "./getMemberIdsByType";

export function getUniqueSelectedMemberIds(
  permissions: Tuple[],
  assetType: AssetType,
) {
  const roles = ["editor", "viewer"] as Tuple["relation"][];

  return getMemberIdsByType(permissions, assetType, roles);
}
