export function isStorybookDocs() {
  const docPath = "/docs/permissions--docs";

  try {
    // Check if running inside an iframe
    if (window.self !== window.top) {
      return window.parent.location.href.includes(docPath);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
