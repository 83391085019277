import { FloatingBox, FloatingMenuProps } from "@zapier/design-system";

import { MenuItem } from "../../components/MenuItem";
import { MenuItemText } from "../../components/MenuItemText";
import { Permission, RoleDefinitions } from "../../types";
import { EDITOR_ITEM, FLOATING_MENU_WIDTH, VIEWER_ITEM } from "../../constants";

import styles from "./RoleDropdownFloatingMenu.module.css";

type Props = {
  roleDefinitions: RoleDefinitions;
  selectedRole: Permission;
  showEditorOption: boolean;
  showViewerOption: boolean;
  getMenuProps: FloatingMenuProps["getMenuProps"];
  getItemProps: FloatingMenuProps["getItemProps"];
  highlightedIndex: FloatingMenuProps["highlightedIndex"];
};

export default function RoleDropdownFloatingMenu({
  selectedRole,
  showEditorOption,
  showViewerOption,
  getMenuProps,
  getItemProps,
  highlightedIndex,
  roleDefinitions,
}: Props) {
  const isEditor = selectedRole === "editor";
  const isViewer = selectedRole === "viewer";

  const menuProps = getMenuProps({ "aria-label": "List of roles" });

  return (
    <FloatingBox align="right" minWidth={`${FLOATING_MENU_WIDTH}px`}>
      <ul className={styles.ul} {...menuProps}>
        {showEditorOption && (
          <MenuItem
            ariaLabel={EDITOR_ITEM.value}
            itemProps={getItemProps({ item: EDITOR_ITEM, disabled: false })}
            isSelected={isEditor}
            isHighlighted={highlightedIndex === 0}
            role="option"
          >
            <MenuItemText
              isSelected={isEditor}
              header="Editor"
              description={roleDefinitions[EDITOR_ITEM.value]}
            />
          </MenuItem>
        )}
        {showViewerOption && (
          <MenuItem
            ariaLabel={VIEWER_ITEM.value}
            itemProps={getItemProps({ item: VIEWER_ITEM, disabled: false })}
            isSelected={isViewer}
            isHighlighted={highlightedIndex === 1}
            role="option"
          >
            <MenuItemText
              isSelected={isViewer}
              header="Viewer"
              description={roleDefinitions[VIEWER_ITEM.value]}
            />
          </MenuItem>
        )}
      </ul>
    </FloatingBox>
  );
}
