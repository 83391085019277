import { useState, useCallback, useEffect } from "react";

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type OnSearchChange = (searchTerm: string) => void;

export function useDebouncedSearchTerm(
  onSearchChange: OnSearchChange,
  delay = 500,
) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = useCallback((searchTerm: string) => {
    setInputValue(searchTerm);
  }, []);

  const debouncedInputValue = useDebounce(inputValue, delay);

  useEffect(() => {
    onSearchChange(debouncedInputValue);
  }, [debouncedInputValue, onSearchChange]);

  return { inputValue, handleInputChange };
}
