import { RefObject, useEffect } from "react";

type UseFocusSearchInputProps = {
  isDisabled: boolean;
  typeaheadRef: RefObject<HTMLInputElement>;
};

const useFocusSearchInput = ({
  isDisabled,
  typeaheadRef,
}: UseFocusSearchInputProps) => {
  useEffect(() => {
    const input = typeaheadRef.current;
    if (!isDisabled && input && !input.disabled) {
      input.focus();
    }
  }, [isDisabled, typeaheadRef]);
};

export default useFocusSearchInput;
