import { AssetType, SelectableType, Tuple } from "../types";

import { getMemberPermission } from "./getMemberPermission";
import { getTeamPermission } from "./getTeamPermission";

export function getMemberOrTeamPermissionData(
  permissions: Tuple[],
  id: number,
  assetType: AssetType,
  type: SelectableType,
) {
  const assetPermission =
    type === "individual"
      ? getMemberPermission(permissions, assetType, id)
      : getTeamPermission(permissions, assetType, id);

  return {
    permission: assetPermission,
  };
}
