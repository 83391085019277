import {
  AssetType,
  Member,
  MemberWithPermission,
  SelectableType,
  Team,
  TeamWithPermission,
  Tuple,
} from "../types";
import { getMemberOrTeamPermissionData } from "./getMemberOrTeamPermissionData";

export function getSelectedMembersOrTeamsWithPermission(
  selectedMembersOrTeams: (Member | Team)[],
  permissions: Tuple[],
  assetType: AssetType,
  type: SelectableType,
) {
  return selectedMembersOrTeams.map((memberOrTeam) => {
    const permissionData = getMemberOrTeamPermissionData(
      permissions,
      memberOrTeam.id,
      assetType,
      type,
    );

    return {
      ...memberOrTeam,
      ...permissionData,
    } as MemberWithPermission | TeamWithPermission;
  });
}
