import { AssetType, Permission, Tuple } from "../types";

import { getOrgPermission } from "./getOrgPermission";

export function getCanvasOrgPermission(
  permissions: Tuple[],
  accountId: number,
  assetType: AssetType,
): Permission {
  const assetOrgPermission = getOrgPermission(
    permissions,
    assetType,
    accountId,
  );

  return assetOrgPermission ?? "no access";
}
