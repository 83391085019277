import {
  Alert,
  Avatar,
  Icon,
  SkeletonBlock,
  SkeletonLoader,
  TagLabel,
  Text,
  ZinniaStylesReset,
  Link,
} from "@zapier/design-system";
import { useCallback, useState, useEffect, Fragment } from "react";

import styles from "./Permissions.module.css";
import { SelectedMembersOrTeams } from "../SelectedMembersOrTeams/SelectedMembersOrTeams";
import {
  Account,
  Member,
  MemberWithPermission,
  ProjectDetails,
  Permission,
  RoleDefinitions,
  Team,
  TeamWithPermission,
  PermissionObjectType,
} from "../../types";
import { DEFAULT_ROLE_DEFINITIONS } from "../../constants";
import { ChangePermissionButton } from "../ChangePermissionButton";
import { SearchInput } from "../SearchInput";
import { Upsell } from "../Upsell";
import { ReadOnlyPermission } from "../ReadOnlyPermission";

type Props = {
  account: Account;
  addMemberOrTeam: (
    memberOrTeam: MemberWithPermission | TeamWithPermission,
  ) => Promise<{ success: boolean }>;
  assetType: PermissionObjectType;
  changeMemberOrTeamPermission?: (
    memberOrTeam: MemberWithPermission | TeamWithPermission,
  ) => Promise<{ success: boolean }>;
  emitEventOnShareUpsell?: () => void;
  /** We have a Team plan which allows multiple members but is only allowed to have 1 team (403 on /teams API). This prop will update the search Typeahead label when you can't search for teams. */
  hasMultipleTeams?: boolean;
  isFetchingOrgPermission?: boolean;
  isFetchingSelectedTeamsAndMembers?: boolean;
  membersSearchResults: Member[];
  teamsSearchResults: Team[];
  onChangeOrgPermission: (
    permission: Permission,
  ) => Promise<{ success: boolean }>;
  orgPermission: Permission;
  onSearch: (searchTerm: string) => void;
  owner: Member | undefined;
  removePermission: (
    memberOrTeam: MemberWithPermission | TeamWithPermission,
  ) => Promise<{ success: boolean }>;
  hasEditorAndViewerRoles: boolean;
  isFetchingSearchResults: boolean;
  isViewOnlyMode: boolean;
  roleDefinitions?: RoleDefinitions;
  /** If not null, we will render a project row to link out to the project and open the manage access modal */
  project?: ProjectDetails | null;
  selectedMembers: MemberWithPermission[];
  selectedTeams: TeamWithPermission[];
  /* Set to "true" if you are not already rendering ZinniaStylesReset or GlobalStyleResets */
  shouldLoadZinniaStylesReset?: boolean;
};

const ownerItems = [{ label: "Owner", value: true }];

export function Permissions({
  account,
  addMemberOrTeam,
  assetType,
  changeMemberOrTeamPermission,
  hasMultipleTeams = true,
  emitEventOnShareUpsell,
  selectedMembers,
  selectedTeams,
  isFetchingOrgPermission,
  isFetchingSelectedTeamsAndMembers,
  isViewOnlyMode,
  onChangeOrgPermission,
  owner,
  orgPermission,
  removePermission,
  hasEditorAndViewerRoles,
  onSearch,
  membersSearchResults,
  project,
  teamsSearchResults,
  isFetchingSearchResults,
  roleDefinitions = DEFAULT_ROLE_DEFINITIONS,
  shouldLoadZinniaStylesReset = false,
}: Props) {
  const allowTeams = account.plan_info.allow_teams;

  const [error, setError] = useState<string | null>(null);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);

  const selectedMembersOrTeams = [...selectedTeams, ...selectedMembers];

  const [isUpdatingOrgPermission, setIsUpdatingOrgPermission] = useState(false);
  const [loadingIds, setLoadingIds] = useState<(Member["id"] | Team["id"])[]>(
    [],
  );
  const [isBusyAdding, setIsBusyAdding] = useState<boolean>(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (error) {
      timer = setTimeout(() => {
        setError(null);
      }, 4000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [error]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (successMsg) {
      timer = setTimeout(() => {
        setSuccessMsg(null);
      }, 4000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [successMsg]);

  const selectedMemberIds = selectedMembers.map((member) => member.id);

  const filteredMembers: Member[] = membersSearchResults.filter(
    (member: Member) => {
      return !selectedMemberIds.includes(member.id) && member.id !== owner?.id;
    },
  );

  const selectedTeamIds = selectedTeams.map((team) => team.id);

  const filteredTeams: Team[] = teamsSearchResults.filter((team: Team) => {
    return !selectedTeamIds.includes(team.id);
  });

  const handleAddMemberOrTeam = useCallback(
    async (memberOrTeam: MemberWithPermission | TeamWithPermission) => {
      setIsBusyAdding(true);

      const isPerson = "full_name" in memberOrTeam;

      try {
        const response = await addMemberOrTeam(memberOrTeam);

        if (response.success) {
          setSuccessMsg(
            `Shared with ${
              isPerson
                ? (memberOrTeam as Member).full_name
                : (memberOrTeam as Team).name
            }.`,
          );
        } else {
          setError(
            `An error occurred while adding the ${
              isPerson ? "member" : "team"
            }.`,
          );
        }
      } catch (error) {
        console.error(
          `Failed to add ${isPerson ? "member" : "team"} permission:`,
          error,
        );
        setError(
          `An error occurred while adding the ${isPerson ? "member" : "team"}.`,
        );
      } finally {
        setIsBusyAdding(false);
      }
    },
    [addMemberOrTeam],
  );

  const handleRemovePermission = useCallback(
    async (memberOrTeam: MemberWithPermission | TeamWithPermission) => {
      const id = memberOrTeam.id;
      const isPerson = "full_name" in memberOrTeam;

      setLoadingIds((prevIds) => [...prevIds, id]);

      try {
        const response = await removePermission(memberOrTeam);

        if (response.success) {
          setSuccessMsg(
            `Removed access for ${
              (memberOrTeam as Team).name || (memberOrTeam as Member).full_name
            }.`,
          );
        } else {
          setError(
            `An error occurred while removing the ${
              isPerson ? "member" : "team"
            }.`,
          );
        }
      } catch (error) {
        console.error(
          `Failed to remove ${isPerson ? "member" : "team"} permission:`,
          error,
        );
        setError(
          `An error occurred while removing the ${
            isPerson ? "member" : "team"
          }.`,
        );
      } finally {
        setLoadingIds((prevIds) => prevIds.filter((item) => item !== id));
      }
    },
    [removePermission],
  );

  const handlePermissionChange = useCallback(
    async (memberOrTeam: MemberWithPermission | TeamWithPermission) => {
      if (typeof changeMemberOrTeamPermission !== "function") {
        return;
      }

      const id = memberOrTeam.id;
      const isPerson = "full_name" in memberOrTeam;

      setLoadingIds((prevIds) => [...prevIds, id]);

      try {
        const response = await changeMemberOrTeamPermission(memberOrTeam);

        if (response.success) {
          setSuccessMsg(
            `Access updated for ${
              (memberOrTeam as Team).name || (memberOrTeam as Member).full_name
            }.`,
          );
        } else {
          setError(
            `An error occurred while updating access for ${
              isPerson ? "member" : "team"
            }.`,
          );
        }
      } catch (error) {
        console.error(
          `Failed to update ${isPerson ? "member" : "team"} permission:`,
          error,
        );
        setError(
          `An error occurred while updating access for ${
            isPerson ? "member" : "team"
          }.`,
        );
      } finally {
        setLoadingIds((prevIds) => prevIds.filter((item) => item !== id));
      }
    },
    [changeMemberOrTeamPermission],
  );

  const handleOrgPermissionChange = useCallback(
    async (permission: Permission) => {
      setIsUpdatingOrgPermission(true);

      const response = await onChangeOrgPermission(permission);
      setIsUpdatingOrgPermission(false);

      if (response.success) {
        const messageMap = {
          editor: `Shared with everyone at ${account.name}.`,
          viewer: `Organization access updated for ${account.name}.`,
          ["no access"]: `Organization access updated for ${account.name}.`,
        };

        const message = messageMap[permission];
        setSuccessMsg(message);
      } else {
        setError("An error occurred while updating account permission.");
      }
    },
    [onChangeOrgPermission, account.name],
  );

  const isBusy = isFetchingSelectedTeamsAndMembers || isFetchingSearchResults;

  const ownerRowStyles = `${styles.ownerRow} ${
    selectedMembersOrTeams.length === 0 ? styles.noBottomBorder : ""
  }`;

  if (!allowTeams) {
    return <Upsell onUpsellButtonClick={emitEventOnShareUpsell} />;
  }

  return (
    <div className={styles.wrapper}>
      {shouldLoadZinniaStylesReset && <ZinniaStylesReset />}
      {error && (
        <div className={styles.alert}>
          <Alert type="error">{error}</Alert>
        </div>
      )}
      {successMsg && (
        <div className={styles.alert}>
          <Alert type="success">{successMsg}</Alert>
        </div>
      )}
      {!isViewOnlyMode && (
        <SearchInput
          hasMultipleTeams={hasMultipleTeams}
          hasEditorAndViewerRoles={Boolean(hasEditorAndViewerRoles)}
          onShare={handleAddMemberOrTeam}
          searchResults={[...filteredTeams, ...filteredMembers]}
          isDisabled={Boolean(
            isFetchingSelectedTeamsAndMembers || isBusyAdding,
          )}
          isBusy={isBusy}
          isBusyAdding={isBusyAdding}
          onInputValueChange={onSearch}
          roleDefinitions={roleDefinitions}
        />
      )}
      <div className={styles.disclaimerContainer}>
        <Text type="smallPrint1">{`Account owners and super admins have access to this ${assetType}.`}</Text>
      </div>
      {project && (
        <div className={styles.inheritedPermissionsRow}>
          <span
            className={`${styles.iconWrapper} ${styles.projectIconWrapper}`}
          >
            <Icon name="navProjects" color="zapier" size={24} />
          </span>
          <span className={styles.singleLineEllipsis}>
            <Text type="paragraph3">
              Anyone in project &ldquo;{project.title}&rdquo;
            </Text>
          </span>
          <div className={styles.inheritedPermissionsCol3}>
            <Link
              href={`/app/projects/${project.id}#manage-access`}
              target="_blank"
            >
              <div className={styles.flexContainer}>
                <Text type="paragraph3Medium" color="blueJeans">
                  See access
                </Text>
                <Icon name="arrowOffsite" size={18} color="blueJeans" />
              </div>
            </Link>
          </div>
        </div>
      )}
      <div className={styles.accountRow}>
        {account.logo_url ? (
          <div className={styles.col1}>
            <Avatar
              isBlock
              name={account.name}
              size={40}
              url={account.logo_url}
            />
          </div>
        ) : (
          <span className={styles.iconWrapper}>
            <Icon name="personGroupFill" color="neutral100" size={22} />
          </span>
        )}
        <span className={styles.singleLineEllipsis}>
          <Text type="paragraph3">Anyone at {account.name}</Text>
        </span>
        <div className={styles.col3}>
          {isViewOnlyMode ? (
            <ReadOnlyPermission
              isLoading={isFetchingOrgPermission}
              permission={orgPermission}
            />
          ) : (
            <ChangePermissionButton
              assetType={assetType}
              permission={orgPermission}
              changePermissionTo={handleOrgPermissionChange}
              removePermission={() => handleOrgPermissionChange("no access")}
              isLoading={isFetchingOrgPermission || isUpdatingOrgPermission}
              removeAccessLabel="No access"
              roleDefinitions={roleDefinitions}
              showEditorOption={
                hasEditorAndViewerRoles ? true : orgPermission !== "editor"
              }
              showViewerOption={hasEditorAndViewerRoles}
              shouldShowRemoveAccess={
                hasEditorAndViewerRoles ? true : orgPermission === "editor"
              }
            />
          )}
        </div>
      </div>
      {!owner?.full_name ? (
        <Fragment>
          <div className={ownerRowStyles}>
            <SkeletonLoader>
              <SkeletonBlock height={40} width={40} borderRadius="50%" />
            </SkeletonLoader>

            <SkeletonLoader>
              <SkeletonBlock height={25} width={120} />
            </SkeletonLoader>

            <SkeletonLoader>
              <SkeletonBlock height={25} width={100} />
            </SkeletonLoader>
          </div>
        </Fragment>
      ) : (
        <div className={ownerRowStyles}>
          <div className={styles.col1}>
            <Avatar
              name={owner?.full_name || "Unknown"}
              url={owner?.photo_url}
              size={40}
            />
          </div>
          <span className={styles.singleLineEllipsis}>
            <Text type="paragraph3">{owner?.full_name || "Unknown"}</Text>
          </span>
          <div className={styles.col3}>
            <div className={styles.tagWrapper}>
              <TagLabel>{ownerItems[0].label}</TagLabel>
            </div>
          </div>
        </div>
      )}
      {selectedMembersOrTeams.length > 0 && (
        <SelectedMembersOrTeams
          assetType={assetType}
          isViewOnlyMode={isViewOnlyMode}
          selectedMembersOrTeams={selectedMembersOrTeams}
          changePermissionTo={handlePermissionChange}
          removePermission={handleRemovePermission}
          hasEditorAndViewerRoles={hasEditorAndViewerRoles}
          roleDefinitions={roleDefinitions}
          loadingIds={loadingIds}
        />
      )}
    </div>
  );
}
