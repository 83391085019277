import { AssetType, Tuple } from "../types";
import { getTeamIdsByType } from "./getTeamIdsByType";

export function getUniqueSelectedTeamIds(
  permissions: Tuple[],
  assetType: AssetType,
) {
  const roles = ["editor", "viewer"] as Tuple["relation"][];

  return getTeamIdsByType(permissions, assetType, roles);
}
