import { PermissionObjectType, Tuple } from "../types";

export function getMemberIdsByType(
  permissions: Tuple[],
  type: PermissionObjectType,
  relationsToInclude: Tuple["relation"][] = [],
) {
  const isCorrectRelation = (tuple: Tuple) => {
    return relationsToInclude.includes(tuple.relation);
  };

  return permissions
    .filter(
      (tuple: Tuple) =>
        tuple.object.includes(type) &&
        isCorrectRelation(tuple) &&
        tuple.user.includes("user"),
    )
    .map((tuple: Tuple) => tuple.user.split(":")[1]);
}
