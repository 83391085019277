export const VIEWER_ITEM = {
  label: "Viewer",
  value: "viewer" as const,
};

export const EDITOR_ITEM = {
  label: "Editor",
  value: "editor" as const,
};

export const FLOATING_MENU_WIDTH = 245;

export const SHARING_MODAL_MAX_WIDTH = 600;

export const DEFAULT_ROLE_DEFINITIONS = {
  editor: "Can edit and manage settings",
  viewer: "Can view",
};

export const STORYBOOK_OFFSET_TOP = 276;

export const STORYBOOK_OFFSET_LEFT = 480;
