import { PermissionObjectType, Tuple } from "../types";

export function getTeamIdsByType(
  permissions: Tuple[],
  type: PermissionObjectType,
  relationsToInclude: Tuple["relation"][] = [],
) {
  return permissions
    .filter(
      (tuple: Tuple) =>
        tuple.object.includes(type) &&
        relationsToInclude.includes(tuple.relation) &&
        /^team:(\d+)#member$/.test(tuple.user),
    )
    .map((tuple: Tuple) => {
      const regex = /^team:(\d+)#member$/;
      const match = tuple.user.match(regex);
      return match ? match[1] : null;
    })
    .filter((id): id is string => id !== null); // Filter out null values to ensure we have valid IDs
}
