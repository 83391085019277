import { Spinner, Text } from "@zapier/design-system";
import { Permission } from "../../types";
import { capitalizeFirstLetter } from "../../utils";
import styles from "./ReadOnlyPermission.module.css";

type Props = {
  isLoading?: boolean;
  permission: Permission;
};

export function ReadOnlyPermission({ permission, isLoading }: Props) {
  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  }

  return (
    <Text margin="0 14px 0 0" type="smallPrint1Medium" color="neutral700">
      {capitalizeFirstLetter(permission)}
    </Text>
  );
}
