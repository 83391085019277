import { Icon, Text, Spinner } from "@zapier/design-system";
import { Tooltip } from "@zapier/design-system-beta";
import { useCallback, useRef, useState } from "react";

import { isStorybookDocs, capitalizeFirstLetter } from "../../utils";
import {
  FLOATING_MENU_WIDTH,
  STORYBOOK_OFFSET_TOP,
  STORYBOOK_OFFSET_LEFT,
} from "../../constants";
import {
  Permission,
  SelectablePermission,
  RoleDefinitions,
  PermissionObjectType,
} from "../../types";
import { FloatingMenu } from "../FloatingMenu";

import styles from "./ChangePermissionButton.module.css";

type Props = {
  assetType: PermissionObjectType;
  isLoading?: boolean;
  permission: Permission;
  changePermissionTo: (permission: SelectablePermission) => void;
  removePermission: () => void;
  roleDefinitions: RoleDefinitions;
  shouldShowRemoveAccess?: boolean;
  showEditorOption: boolean;
  showViewerOption: boolean;
  removeAccessLabel?: string;
};

export default function ChangePermissionButton({
  assetType,
  isLoading,
  permission,
  changePermissionTo,
  removePermission,
  roleDefinitions,
  showEditorOption,
  showViewerOption,
  shouldShowRemoveAccess = true,
  removeAccessLabel = "Remove access",
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [floatingBoxStyles, setFloatingBoxStyles] = useState({});

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const onClick = useCallback(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const topOffset = 6;
      const storybookOffset = { top: 0, left: 0 };

      if (isStorybookDocs()) {
        storybookOffset.top = STORYBOOK_OFFSET_TOP;
        storybookOffset.left = STORYBOOK_OFFSET_LEFT;
      }

      const newPositionStyle = {
        top: `${buttonRect.bottom - storybookOffset.top + topOffset}px`, // Position below the button
        left: `${
          buttonRect.right - storybookOffset.left - FLOATING_MENU_WIDTH
        }px`, // Align right edges
      };

      setFloatingBoxStyles(newPositionStyle);
    }

    setIsMenuOpen((prev) => !prev);
  }, []);

  const onClickOutside = useCallback(() => {
    // setTimeout or else the menu closes first and clicking the button will open the dropdown again so the dropdown can never be toggled
    setTimeout(closeMenu, 0);
  }, [closeMenu]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <button disabled={true} className={styles.buttonNoStyle}>
          <Spinner />
        </button>
      </div>
    );
  }

  return (
    <>
      <div className={styles.roleChangeButtonWrapper}>
        <Tooltip placement="left" content="Update role by selecting an option.">
          <Tooltip.FocusableTrigger>
            <button
              ref={buttonRef}
              className={styles.buttonNoStyle}
              onClick={onClick}
            >
              <Text type="smallPrint1Medium" color="neutral700">
                {capitalizeFirstLetter(permission)}
              </Text>
              <Icon name="arrowDropdown" size={24} color="neutral600" />
            </button>
          </Tooltip.FocusableTrigger>
        </Tooltip>
      </div>
      {isMenuOpen && (
        <FloatingMenu
          assetType={assetType}
          closeMenu={closeMenu}
          onClickOutside={onClickOutside}
          dynamicStyles={floatingBoxStyles}
          showEditorOption={showEditorOption}
          showViewerOption={showViewerOption}
          selectedRole={permission}
          changePermissionTo={(newPermission: SelectablePermission) => {
            closeMenu();
            changePermissionTo(newPermission);
          }}
          removeAccessLabel={removeAccessLabel}
          removePermission={() => {
            closeMenu();
            removePermission();
          }}
          roleDefinitions={roleDefinitions}
          shouldShowRemoveAccess={shouldShowRemoveAccess}
        />
      )}
    </>
  );
}
