import styles from "./MenuItemText.module.css";

type Props = {
  header: string;
  description: string;
  isSelected: boolean;
};

export default function MenuItemText({
  header,
  description,
  isSelected,
}: Props) {
  const headerClasses = `${styles.header} ${
    isSelected ? styles.headerSelected : ""
  }`;

  return (
    <>
      <div className={headerClasses}>{header}</div>
      <p className={styles.description}>{description}</p>
    </>
  );
}
