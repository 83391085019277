import { RefObject, useCallback, useEffect, useState } from "react";

export default function useMoreButtonHook(
  elementRef: RefObject<HTMLUListElement>,
  numOfSelectedMembersAndTeams: number,
) {
  const [showMoreButton, setShowMoreButton] = useState(false);

  // Check if the "More" button should be shown when the number of selected members and teams changes.
  // This effect is triggered both on component mount and whenever the number of items changes.
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const scrollableAncestor = element.closest("div[data-scrollable]");
      if (scrollableAncestor) {
        const checkScrollbarPresence = () => {
          const hasSufficientOverflow =
            scrollableAncestor.scrollHeight >
            scrollableAncestor.clientHeight + 60; // 60 so we have some buffer or else you can see every item
          setShowMoreButton(hasSufficientOverflow);
        };

        // Perform the check after a short delay to ensure all content has been fully loaded and styles applied
        const timer = setTimeout(checkScrollbarPresence, 200);

        return () => {
          clearTimeout(timer); // Clear the timeout on cleanup to avoid unwanted execution after unmounting
        };
      }
    }
  }, [elementRef, numOfSelectedMembersAndTeams]);

  // Monitor the scroll position to dynamically show or hide the 'More' button based on whether the user has scrolled to the bottom.
  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const scrollableAncestor = element.closest("div[data-scrollable]");

      if (scrollableAncestor) {
        // Function to check if the scrollable content has reached near the bottom
        const checkIfScrolledToBottom = () => {
          const bottomReached =
            Math.ceil(
              scrollableAncestor.scrollTop +
                scrollableAncestor.clientHeight +
                26, // Padding bottom is 26 pixels for ModalContentBody, so we account for that
            ) >= scrollableAncestor.scrollHeight;
          setShowMoreButton(!bottomReached);
        };

        scrollableAncestor.addEventListener("scroll", checkIfScrolledToBottom);
        return () => {
          scrollableAncestor.removeEventListener(
            "scroll",
            checkIfScrolledToBottom,
          );
        };
      }
    }
  }, [elementRef]);

  // Function to handle 'More' button click, intended to scroll further down the content or to the top if at bottom
  const handleMoreClick = useCallback(() => {
    const element = elementRef.current;
    if (element) {
      const scrollableAncestor = element.closest("div[data-scrollable]");

      if (scrollableAncestor) {
        // Subtract a buffer from the clientHeight to slightly undershoot the desired scroll increment
        const buffer = 20;
        const scrollIncrement = scrollableAncestor.clientHeight - buffer;
        const newScrollPosition =
          scrollableAncestor.scrollTop + scrollIncrement;

        // Execute smooth scrolling to the new position
        scrollableAncestor.scrollTo({
          top: newScrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [elementRef]);

  return {
    showMoreButton,
    handleMoreClick,
  };
}
