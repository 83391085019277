import styles from "./SelectedMembersOrTeams.module.css";
import { Avatar, Icon, Text } from "@zapier/design-system";
import {
  Member,
  MemberWithPermission,
  PermissionObjectType,
  RoleDefinitions,
  Team,
  TeamWithPermission,
} from "../../";
import { ChangePermissionButton } from "../ChangePermissionButton";
import useMoreButtonHook from "../../hooks/useMoreButtonHook";
import { useRef } from "react";
import { ReadOnlyPermission } from "../ReadOnlyPermission";

type Props = {
  assetType: PermissionObjectType;
  isViewOnlyMode: boolean;
  selectedMembersOrTeams: (MemberWithPermission | TeamWithPermission)[];
  removePermission: (
    memberOrTeam: MemberWithPermission | TeamWithPermission,
  ) => void;
  changePermissionTo: (id: MemberWithPermission | TeamWithPermission) => void;
  hasEditorAndViewerRoles: boolean;
  loadingIds: (Member["id"] | Team["id"])[];
  roleDefinitions: RoleDefinitions;
};

export function SelectedMembersOrTeams({
  assetType,
  isViewOnlyMode,
  selectedMembersOrTeams,
  removePermission,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePermissionTo = () => {},
  hasEditorAndViewerRoles,
  loadingIds,
  roleDefinitions,
}: Props) {
  const elementRef = useRef(null);

  const { showMoreButton, handleMoreClick } = useMoreButtonHook(
    elementRef,
    selectedMembersOrTeams.length,
  );

  return (
    <>
      <ul ref={elementRef} className={styles.shareList}>
        {selectedMembersOrTeams.map((memberOrTeam) => {
          const isLoading = loadingIds.includes(memberOrTeam.id);

          const name =
            (memberOrTeam as Member).full_name || (memberOrTeam as Team).name;

          return (
            <li key={memberOrTeam.id} className={styles.shareRow}>
              <div className={styles.col1}>
                <Avatar
                  name={name}
                  url={
                    (memberOrTeam as Member).photo_url ||
                    (memberOrTeam as Team).logo
                  }
                  size={40}
                />
              </div>
              <span className={styles.singleLineEllipsis}>{name}</span>
              <div className={styles.col3}>
                {isViewOnlyMode ? (
                  <ReadOnlyPermission permission={memberOrTeam.permission} />
                ) : (
                  <ChangePermissionButton
                    assetType={assetType}
                    permission={memberOrTeam.permission}
                    changePermissionTo={(permission) =>
                      changePermissionTo({
                        ...memberOrTeam,
                        permission,
                      })
                    }
                    isLoading={isLoading}
                    showEditorOption={hasEditorAndViewerRoles}
                    showViewerOption={hasEditorAndViewerRoles}
                    removePermission={() => removePermission(memberOrTeam)}
                    roleDefinitions={roleDefinitions}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {showMoreButton && (
        <button className={styles.moreButton} onClick={handleMoreClick}>
          <Icon name="arrowBigDown" size={18} />
          <Text type="smallPrint1Semibold" color="inherit">
            More
          </Text>
        </button>
      )}
    </>
  );
}
