/** @jsx jsx */

'use client';

import { Global, css, jsx } from '@emotion/react';

const styles = css`
  /***
 Modified from the new CSS reset - version 1.8.4 (last updated 14.2.2023)
 GitHub page: https://github.com/elad2412/the-new-css-reset
***/

  /*
 Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
 - The "symbol *" part is to solve Firefox SVG sprite bug
*/
  *:where(
      :not(
          html,
          iframe,
          canvas,
          code,
          img,
          svg,
          table,
          thead,
          th,
          td,
          tbody,
          tr,
          video,
          audio
        ):not(svg *, symbol *)
    ) {
    all: unset;
    display: revert;
    box-sizing: border-box;
  }

  /* Preferred box-sizing value */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Reapply the pointer cursor for anchor tags */
  a,
  label,
  button:not([disabled]) {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-inline-size: 100%;
    max-block-size: 100%;
  }

  /* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
  input,
  textarea {
    -webkit-user-select: auto;
  }

  /* revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }

  /* minimum style to allow to style meter element */
  meter {
    -webkit-appearance: revert;
    appearance: revert;
  }

  /* preformatted text - use only for this feature */
  :where(pre) {
    all: revert;
  }

  /* reset default text opacity of input placeholder */
  ::placeholder {
    color: unset;
  }

  /* remove default dot (•) sign */
  :marker {
    content: initial;
  }

  /* fix the feature of 'hidden' attribute.
display:revert; revert to element instead of attribute */
  :where([hidden]) {
    display: none;
  }

  /* revert for bug in Chromium browsers
- fix for the content editable attribute will work properly.
- webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
  :where([contenteditable]:not([contenteditable='false'])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
  }

  /* apply back the draggable feature - exist only in Chromium and Safari */
  :where([draggable='true']) {
    -webkit-user-drag: element;
  }

  /* Revert Modal native behavior */
  :where(dialog:modal) {
    all: revert;
  }
`;

/**
 * This component provides global styles to reset all CSS properties
 * on our components so we don't have to deal with style conflicts from
 * external styles. Note, this can also be used as a reset for your entire service
 * This reset does not work well in the monolith without a great deal of effort.
 * This only needs to be rendered in an application once
 * ```jsx
 * <GlobalStylesReset />
 * ```
 *
 */
export const GlobalStylesReset = () => {
  return <Global styles={styles} />;
};
