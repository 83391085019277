import { Dropdown, Field } from "@zapier/design-system";

import { RoleDropdownFloatingMenu } from "../RoleDropdownFloatingMenu";
import { EDITOR_ITEM, VIEWER_ITEM } from "../../constants";
import { RoleDefinitions, SelectablePermission } from "./../../types";

import styles from "./RoleDropdown.module.css";

type Props = {
  initialSelectedItem: typeof VIEWER_ITEM | typeof EDITOR_ITEM;
  hasEditorAndViewerRoles: boolean;
  roleDefinitions: RoleDefinitions;
  onChange: (selectedRole: SelectablePermission) => void;
};

const DROPDOWN_ITEMS = [VIEWER_ITEM, EDITOR_ITEM];

export default function RoleDropdown({
  initialSelectedItem,
  hasEditorAndViewerRoles,
  roleDefinitions,
  onChange,
}: Props) {
  return (
    <div className={styles.searchInputCol2}>
      <Field
        label="Role"
        renderInput={(inputProps) => (
          <Dropdown
            {...inputProps}
            inputId={inputProps.id}
            ariaLabel="Role"
            initialSelectedItem={initialSelectedItem}
            items={DROPDOWN_ITEMS}
            menuAriaLabel="List of available roles"
            onChange={(item) => onChange(item.value)}
            renderFloatingMenu={(menuProps) => {
              return (
                <RoleDropdownFloatingMenu
                  {...menuProps}
                  roleDefinitions={roleDefinitions}
                  showEditorOption={true}
                  showViewerOption={hasEditorAndViewerRoles}
                  selectedRole={menuProps.selectedItem.value}
                />
              );
            }}
          />
        )}
      />
    </div>
  );
}
