import { PermissionObjectType, Tuple } from "../types";

export function getMemberPermission(
  permissions: Tuple[],
  type: PermissionObjectType,
  id: number,
): Tuple["relation"] | undefined {
  return permissions.find(
    (tuple: Tuple) =>
      tuple.object.includes(type) &&
      tuple.user.includes(`user:${id.toString()}`),
  )?.relation;
}
