import { useCallback, useRef, useState } from "react";
import { Avatar, Field, Typeahead } from "@zapier/design-system";
import { Button } from "@zapier/design-system-beta";

import {
  Member,
  MemberWithPermission,
  RoleDefinitions,
  SelectablePermission,
  Team,
  TeamWithPermission,
} from "../../types";
import { EDITOR_ITEM } from "../../constants";
import useFocusSearchInput from "../../hooks/useFocusSearchInput";
import { useDebouncedSearchTerm } from "../../hooks/useDebouncedSearchTerm";
import { RoleDropdown } from "../RoleDropdown";

import styles from "./SearchInput.module.css";

type Props = {
  hasEditorAndViewerRoles: boolean;
  hasMultipleTeams: boolean;
  isDisabled: boolean;
  isBusy: boolean;
  isBusyAdding: boolean;
  onShare: (item: MemberWithPermission | TeamWithPermission) => Promise<void>;
  searchResults: (Member | Team)[];
  onInputValueChange: (searchTerm: string) => void;
  roleDefinitions: RoleDefinitions;
};

export default function SearchInput({
  hasMultipleTeams,
  hasEditorAndViewerRoles,
  onShare,
  searchResults,
  isDisabled,
  isBusy,
  isBusyAdding,
  onInputValueChange,
  roleDefinitions,
}: Props) {
  const initialSelectedItem = EDITOR_ITEM;

  const [selectedItem, setSelectedItem] = useState<Member | Team | null>(null);

  const [selectedRole, setSelectedRole] = useState<SelectablePermission>(
    initialSelectedItem.value,
  );

  const getLabelForItem = useCallback((memberOrTeam: Member | Team) => {
    const isPerson = "full_name" in memberOrTeam;
    if (isPerson) {
      return memberOrTeam.full_name;
    }

    return `${memberOrTeam.name}, ${memberOrTeam.member_count} members`;
  }, []);

  const renderIcon = useCallback(
    (memberOrTeam: Member | Team) => (
      <Avatar
        name={(memberOrTeam as Member).full_name || (memberOrTeam as Team).name}
        url={(memberOrTeam as Member).photo_url || (memberOrTeam as Team).logo}
        size={25}
      />
    ),
    [],
  );

  // @ts-ignore
  const setClearSelection = (f) => (clearSelection = f);
  let clearSelection = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setClearSelection],
  );

  const typeaheadRef = useRef<HTMLInputElement>(null);
  useFocusSearchInput({ isDisabled, typeaheadRef });

  const { inputValue, handleInputChange } =
    useDebouncedSearchTerm(onInputValueChange);

  const searchInputWrapperRef = useRef<HTMLDivElement>(null);

  const onBlur = useCallback(() => {
    // This is to prevent multiple floating menus to be open all at once when using keyboard navigation
    setTimeout(() => {
      if (searchInputWrapperRef.current) {
        const openDropdown = searchInputWrapperRef.current.querySelector(
          'div[role="combobox"][aria-expanded="true"]',
        );

        if (openDropdown) {
          clearSelection();
        }
      }
    }, 0);
  }, [clearSelection, searchInputWrapperRef]);

  return (
    <>
      <div className={styles.searchInputWrapper}>
        <div ref={searchInputWrapperRef} className={styles.searchInputCol1}>
          <Field
            label={hasMultipleTeams ? "Add member or team" : "Add member"}
            renderInput={(inputProps) => (
              <Typeahead
                {...inputProps}
                inputRef={typeaheadRef}
                menuAriaLabel="Permissions"
                placeholder=""
                onChange={(item) => setSelectedItem(item)}
                items={searchResults}
                getLabelForItem={getLabelForItem}
                renderIcon={renderIcon}
                isDisabled={isDisabled}
                inputValue={inputValue}
                onInputValueChange={handleInputChange}
                isBusy={isBusy}
                setClearSelection={setClearSelection}
                onBlur={onBlur}
              />
            )}
          />
        </div>
        <RoleDropdown
          initialSelectedItem={initialSelectedItem}
          hasEditorAndViewerRoles={hasEditorAndViewerRoles}
          roleDefinitions={roleDefinitions}
          onChange={setSelectedRole}
        />
        <div className={styles.searchInputCol3}>
          <Button
            isDisabled={!selectedItem || isDisabled}
            isLoading={isBusyAdding}
            onPress={async () => {
              if (selectedItem) {
                await onShare({
                  ...selectedItem,
                  permission: selectedRole,
                });

                clearSelection();
              }
            }}
          >
            Share
          </Button>
        </div>
      </div>
    </>
  );
}
