import { useCallback, useEffect, useState, KeyboardEvent, useRef } from "react";
import { FloatingBox, Text, useClickOutside } from "@zapier/design-system";

import { MenuItem } from "../../components/MenuItem";
import { MenuItemText } from "../../components/MenuItemText";
import {
  Permission,
  SelectablePermission,
  RoleDefinitions,
  PermissionObjectType,
} from "../../types";
import { FLOATING_MENU_WIDTH, EDITOR_ITEM, VIEWER_ITEM } from "../../constants";

import styles from "./FloatingMenu.module.css";

type Props = {
  assetType: PermissionObjectType;
  dynamicStyles:
    | {
        top: string;
        left: string;
      }
    | object;
  selectedRole: Permission;
  changePermissionTo: (permission: SelectablePermission) => void;
  removePermission: () => void;
  onClickOutside: () => void;
  closeMenu: () => void;
  removeAccessLabel: string;
  roleDefinitions: RoleDefinitions;
  showEditorOption: boolean;
  showViewerOption: boolean;
  shouldShowRemoveAccess: boolean;
};

export default function FloatingMenu({
  assetType,
  dynamicStyles,
  selectedRole,
  changePermissionTo,
  removePermission,
  roleDefinitions,
  showEditorOption,
  showViewerOption,
  onClickOutside,
  shouldShowRemoveAccess,
  closeMenu,
  removeAccessLabel,
}: Props) {
  const isEditor = selectedRole === "editor";
  const isViewer = selectedRole === "viewer";

  const [isConfirmingRemove, setIsConfirmingRemove] = useState(false);
  const removeAccessText = isConfirmingRemove
    ? `Remove ${assetType} access?`
    : removeAccessLabel;

  const floatingBoxRef = useRef<HTMLUListElement>(null);

  // If we don't do this, the menu will be stuck in the same place while the user row has moved
  useEffect(() => {
    const floatingBox = floatingBoxRef.current;

    if (!floatingBox) return;

    // Traverse up the DOM tree and find the closest scrollable ancestor
    const scrollableAncestor = floatingBox.closest("div[data-scrollable]");

    if (scrollableAncestor) {
      scrollableAncestor.addEventListener("scroll", closeMenu);

      return () => {
        scrollableAncestor.removeEventListener("scroll", closeMenu);
      };
    }
  }, [closeMenu]);

  // To prevent menu being stuck in a weird place when browser size changes
  useEffect(() => {
    window.addEventListener("resize", closeMenu);

    return () => {
      window.removeEventListener("resize", closeMenu);
    };
  }, [closeMenu]);

  const handleRemoveAccess = useCallback(() => {
    if (isConfirmingRemove) {
      removePermission();
    } else {
      setIsConfirmingRemove(true);
      setTimeout(() => {
        setIsConfirmingRemove(false);
      }, 5000);
    }
  }, [isConfirmingRemove, setIsConfirmingRemove, removePermission]);

  const [setClickOutsideNode] = useClickOutside({
    onClickOutside,
  });

  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const [menuItems, setMenuItems] = useState<Permission[]>([]);

  useEffect(() => {
    const items: Permission[] = [];
    if (showEditorOption) items.push("editor");
    if (showViewerOption) items.push("viewer");
    if (shouldShowRemoveAccess) items.push("no access");

    setMenuItems(items);
  }, [showEditorOption, showViewerOption, shouldShowRemoveAccess]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!["ArrowUp", "ArrowDown"].includes(event.key)) return;

      event.preventDefault();

      setHighlightedIndex((prevIndex) => {
        let newIndex = prevIndex;

        if (event.key === "ArrowDown") {
          newIndex = (prevIndex + 1) % menuItems.length; // Increment index or wrap around
        } else if (event.key === "ArrowUp") {
          newIndex = (prevIndex - 1 + menuItems.length) % menuItems.length; // Decrement index or wrap around
        }

        return newIndex;
      });
    };

    document.addEventListener(
      "keydown",
      handleKeyDown as unknown as EventListener,
    );

    return () => {
      document.removeEventListener(
        "keydown",
        handleKeyDown as unknown as EventListener,
      );
    };
  }, [menuItems.length]);

  return (
    <div
      ref={setClickOutsideNode}
      style={dynamicStyles}
      className={styles.floatingBoxWrapper}
    >
      <FloatingBox align="right" minWidth={`${FLOATING_MENU_WIDTH}px`}>
        <ul
          ref={floatingBoxRef}
          className={styles.ul}
          aria-label="List of roles"
          role="listbox"
        >
          {showEditorOption && (
            <MenuItem
              ariaLabel="Editor"
              isSelected={isEditor}
              isHighlighted={highlightedIndex === menuItems.indexOf("editor")}
              shouldHandleFocus={true}
              role="option"
              onClick={() => {
                changePermissionTo("editor");
              }}
            >
              <MenuItemText
                isSelected={isEditor}
                header="Editor"
                description={roleDefinitions[EDITOR_ITEM.value]}
              />
            </MenuItem>
          )}
          {showViewerOption && (
            <MenuItem
              ariaLabel="Viewer"
              isSelected={isViewer}
              isHighlighted={highlightedIndex === menuItems.indexOf("viewer")}
              shouldHandleFocus={true}
              role="option"
              onClick={() => changePermissionTo("viewer")}
            >
              <MenuItemText
                isSelected={isViewer}
                header="Viewer"
                description={roleDefinitions[VIEWER_ITEM.value]}
              />
            </MenuItem>
          )}
          {shouldShowRemoveAccess && (
            <MenuItem
              ariaLabel={removeAccessLabel}
              hasTopDivider={showEditorOption && showViewerOption}
              onClick={handleRemoveAccess}
              isHighlighted={
                highlightedIndex === menuItems.indexOf("no access")
              }
              shouldHandleFocus={true}
              role="option"
            >
              <Text type="paragraph3" color="error500">
                {removeAccessText}
              </Text>
            </MenuItem>
          )}
        </ul>
      </FloatingBox>
    </div>
  );
}
